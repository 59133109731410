<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Trainings</h2>
        <div  v-if="trainings.length > 0">
            <v-card v-for="training in trainings" v-bind:key="training.id" outlined elevation="2" class="mt-2 pa-3">
                <h3 style="color: #002060;">{{ training.category.category }} 
                    <v-btn v-if="training.status == 'active'" color="primary" style="float: right;text-transform: capitalize;" small class="success">Valid</v-btn> 
                    <v-btn v-else style="float: right;text-transform: capitalize;" small class="red">Expired</v-btn> 
                </h3>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Status: </strong>{{ training.version }}</p>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Date trained: </strong>{{ training.date_certified }}</p>
                <p style="color: #002060; margin-bottom:0px !important;"><strong>Expiry date: </strong>{{ training.expiry_date }}</p>
                <a v-if="training.document" target="blank"  color="primary" :href="training.document"
                style="text-transform: capitalize;" small class="my-anchor-tag mt-1">View Document</a> 
                <v-btn v-if="training.status != 'active'" @click="requestTraining(training.id)" style="text-transform: capitalize;" small class="primary">Request Training</v-btn> 
            </v-card>
        </div>
        <div v-else>
            <p style="color: #002060; text-align: center; margin-top: 20px; padding: 10px; margin-bottom:0px !important;">No Training Found</p>
        </div>

    </v-container>
</template>

<script>

import Swal from 'sweetalert2'
export default {
    name:'PassportTraining',
    data: () => ({
        user_position: "",
        user_id: "",
        user:{},
        trainings:[],
    }),
    methods:{
        async requestTraining (trainingId) {
            const requestOptions = { 
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    user: this.user.id,
                    type: this.user.type,
                    training: trainingId
                })
            };
            const res = await fetch('https://camusatweb.ubuniworks.com/api/v1/auth/trainings', requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                    icon: 'success',
                    title: 'Training request submitted successfully',
                    text: data.message
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Staff Account not found. Login again',
                    text: data.message
                })
                this.$router.push('/')
            }
        }
    },
    mounted () {
        this.user = JSON.parse(localStorage.getItem('user'))
        if(this.user == null){
            this.$router.push('/')
        }
        this.trainings = JSON.parse(localStorage.getItem('trainings'))

    }

}
</script>

<style scoped>
.my-anchor-tag {
  display: inline-block;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #002060; /* Replace with the background color of v-btn */
}
</style>